<template>
  <div :class="['text-default-input',
    isBordered ? 'text-default-input--bordered' : '',
    isAverage ? 'text-default-input--average' : '',
    disabled ? 'text-default-input--disabled' : ''
    ]"
  >
    <label :for="label">
      <input
        v-if="onChange"
        :id="label"
        @change="e => onChange(e.target.value)"
        :type="type"
        :value="value"
        :placeholder="placeholder"
        :disabled="disabled"
      >
      <input
        v-else
        :id="label"
        :v-model="setValue[id]"
        :type="type"
        :placeholder="placeholder"
        :disabled="disabled"
        :maxlength="maxlength"
        :autocomplete="autocomplete ? '' : 'new-password'"
      >
    </label>
    <div v-if="errorMessages.length">
      <p v-for="error in errorMessages" :key="error.id" class="text-default-input__error">
       {{error}}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputText',
  props: {
    isAverage: {
      type: Boolean,
      default: false
    },
    isBordered: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number]
    },
    setValue: {
      type: Object,
      default () {
        return {}
      }
    },
    autocomplete: {
      type: Boolean,
      default: true,
    },
    maxlength: {
      type: Number,
      default: 999,
    },
    errorMessages: {
      type: Array,
      default () {
        return []
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    onChange: {
      type: [Function, Boolean],
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.text-default-input {
  input {
    width: 100%;
    background: $secondColor;
    border-radius: 55px;
    height: 46px;
    border: none;
    outline: none;
    font-size: $firstFont;
    padding: 17px 0 16px 16px;
    &::placeholder {
      color: grey;
      padding: 16px 0px;
      font-size: $firstFont;
    }
  }
  &--average {
    input {
      height: 36px;
    }
  }
  &--bordered {
    input {
      border: 1px solid grey;
      box-sizing: border-box;
    }
  }
  &__error {
    color: red;
    padding-left: 10px;
    text-align: left;
  }

  &--disabled {
  }
}
@media (max-width: $break-sm) {
  .text-default-input {

    input {
      height: 36px;
    }
  }
}
</style>
